import React from 'react';
import { Helmet } from 'react-helmet';

import Footer from '../components/Footer/Footer';
import ImageText from '../components/ImageText/ImageText';
import externalLinks from '../utils/externalLinks';

const ContactPage = () => (
  <>
    <Helmet title="HSE - Kontakt" />
    <ImageText text="Ihr Kontakt" src={require('assets/images/Kontakt_Service.jpg')} />

    <Footer
      contactContent='Home Shopping Europe GmbH </br>
      Münchener Straße 101h · 85737 Ismaning · Germany
      · T <a href="tel:+4989960600">+49 89 960 60 0</a>
      · F <a href="tel:+4989960606110">+49 89 960 60 6110</a></br>
        <a href="mailto:service@hse.com">service@hse.com</a>'
      socialMediaLinks={[
        {
          icon: {
            src: require('assets/icons/facebook.svg'),
            alt: 'Facebook page',
          },
          href: externalLinks.facebook,
        },
        {
          icon: {
            src: require('assets/icons/instagram.svg'),
            alt: 'Instagram profile',
          },
          href: externalLinks.instagram,
        },
        {
          icon: {
            src: require('assets/icons/pinterest.svg'),
            alt: 'Pinterest profile',
          },
          href: externalLinks.pinterest,
        },
        {
          icon: {
            src: require('assets/icons/youtube.svg'),
            alt: 'Youtube channel',
          },
          href: externalLinks.youtube,
        },
        {
          icon: {
            src: require('assets/icons/linkedin.svg'),
            alt: 'Linkedin profile',
          },
          href: externalLinks.linkedin,
        },
        {
          icon: {
            src: require('assets/icons/xing.svg'),
            alt: 'Xing profile',
          },
          href: externalLinks.xing,
        },
      ]}
      mobileAppsLinks={[
        {
          icon: {
            src: require('assets/images/apple-store.png'),
            alt: 'ios app',
          },
          href: externalLinks.ios_app,
        },
        {
          icon: {
            src: require('assets/images/google-play-store.png'),
            alt: 'android app',
          },
          href: externalLinks.android_app,
        },
      ]}
      subFooterContent={{
        legalLinks: [
          {
            text: 'Impressum',
            href: externalLinks.impressum,
          },
          {
            text: 'Datenschutz',
            href: externalLinks.datenschutz,
          },
        ],
        copyrightText: `Alle Rechte vorbehalten © HSE ${new Date().getFullYear()}`,
      }}
    />
  </>
);

export default ContactPage;
