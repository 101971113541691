import ParabolicShapeSvg from 'assets/icons/parabolic-shape.inline.svg';
import React from 'react';
import { defaultNavbarLinks } from '../../constants/constants';
import Navbar from '../Hero/Navbar/Navbar';
import SupplierMenu from '../Supplier/Menu';

import styles from './ImageText.module.scss';

interface ImageText {
  text: string;
  src: string;
  alt?: string;
  page?: string;
  supplier?: boolean;
}

function ImageText({ text, src, alt, supplier, page }: ImageText) {
  return (
    <div className={styles.wrapper}>
      <Navbar links={defaultNavbarLinks} />
      {supplier ?
        <div className={styles.supplier}>
          <h1>{page}</h1>
          <h2>{text}</h2>
          <SupplierMenu/>
        </div>
        :
        <h1>{text}</h1>
      }
      <img className={styles.cover_image} src={src} alt={alt} />
      {!supplier && <ParabolicShapeSvg />}

    </div>
  );
}

export default ImageText;
